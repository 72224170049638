// src/Data.js
import { useTranslation } from 'react-i18next';

const createCertificateLink = (url, t, customText) =>
    `<a href="${url}" target="_blank" rel="noopener noreferrer">${customText || t('Modal.cert')}</a>`;
const createLink = (url, text) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`;

export function Projects() {
    const { t } = useTranslation();

    return [
        {
            name: t('ProjectsModal.nameISON'),
            className: 'highlight',
            details: [
                { text: `${t('ProjectsModal.details.patent')} ${createLink('https://new.fips.ru/registers-doc-view/fips_servlet?DB=EVM&DocNumber=2021662181&TypeFile=html', '2021662181')}`, className: 'emph' },
                { text: t('ProjectsModal.details.energyConsumption'), className: 'emph' },
                { text: t('ProjectsModal.details.decisionMaking'), className: 'emph' },
                { text: t('ProjectsModal.details.mlAlgorithms'), className: 'emph' },
            ],
        },
        {
            name: t('projects.project1.name'),
            className: 'highlight',
            details: [
                { text: t('projects.project1.details.detail1'), className: 'emph' },
                { text: t('projects.project1.details.detail2'), className: 'emph' },
            ],
        },
        {
            name: t('projects.project2.name'),
            className: 'highlight',
            details: [
                { text: t('projects.project2.details.detail1'), className: 'emph' },
                { text: t('projects.project2.details.detail2'), className: 'emph' },
                { text: t('projects.project2.details.detail3'), className: 'emph' },
            ],
        },
        {
            name: t('projects.project3.name'),
            className: 'highlight',
            details: [
                { text: t('projects.project3.details.detail1'), className: 'emph' },
                { text: t('projects.project3.details.detail2'), className: 'emph' },
            ],
        },
        {
            name: t('projects.project4.name'),
            className: 'highlight',
            details: [
                { text: t('projects.project4.details.detail1'), className: 'emph' },
                { text: t('projects.project4.details.detail2'), className: 'emph' },
            ],
        },
    ];
}

export function Courses() {
    const { t } = useTranslation();

    return [
        {
            name: 'Stepik',
            className: 'highlight',
            details: [
                { text: `Introductory Econometrics: a Practical Approach (${createCertificateLink(`${process.env.PUBLIC_URL}/econom.pdf`, t)})`, className: 'emph' },
                { text: t('CoursesModal.profPM'), className: 'emph' },
                { text: t('CoursesModal.generationP'), className: 'emph' },
                { text: t('CoursesModal.interectSQL'), className: 'emph' },
                { text: `${t('CoursesModal.SEO')} (${createCertificateLink(`${process.env.PUBLIC_URL}/SEO.png`, t)})`, className: 'emph' },
                { text: `${t('CoursesModal.Agile')} (${createCertificateLink(`${process.env.PUBLIC_URL}/Agile.png`, t)})`, className: 'emph' },
                { text: `${t('CoursesModal.Marketing')} (${createCertificateLink(`${process.env.PUBLIC_URL}/Marketing.png`, t)})`, className: 'emph' },
            ],
        },
        {
            name: 'Kaggle',
            className: 'highlight',
            details: [
                { text: `Data Visualization (${createCertificateLink(`${process.env.PUBLIC_URL}/DataVisualization.png`, t)})`, className: 'emph' },
                { text: `Advanced SQL (${createCertificateLink(`${process.env.PUBLIC_URL}/AdvancedSQL.png`, t)})`, className: 'emph' },
                { text: `Data Cleaning (${createCertificateLink(`${process.env.PUBLIC_URL}/DataCleaning.png`, t)})`, className: 'emph' },
                { text: `Python (${createCertificateLink(`${process.env.PUBLIC_URL}/Python.png`, t)})`, className: 'emph' },
            ],
        },
        {
            name: t('CoursesModal.DevOpsname'),
            className: 'highlight',
            details: [
                { text: `${t('CoursesModal.DevOps')} (${createCertificateLink(`${process.env.PUBLIC_URL}/devOPS.png`, t)})`, className: 'emph' },
            ],
        },
        {
            name: 'Sololearn',
            className: 'highlight',
            details: [
                { text: `HTML (${createCertificateLink(`${process.env.PUBLIC_URL}/HTML.jpg`, t)})`, className: 'emph' },
                { text: `CSS (${createCertificateLink(`${process.env.PUBLIC_URL}/css.jpg`, t)})`, className: 'emph' },
            ],
        },
        {
            name: 'Coursera',
            className: 'highlight',
            details: [
                { text: 'Master of Applied Data Science', className: 'emph' },
                { text: 'Google Project Management', className: 'emph' },
                { text: 'Introduction to Data Analytics', className: 'emph' },
            ],
        },
        { name: t('CoursesModal.nameKarpov'), className: 'highlight' },
    ];
}

export function Conferences() {
    const { t } = useTranslation();

    return [
        {
            name: t('ConferencesModal.name'),
            className: 'highlight',
            details: [
                { text: `${t('ConferencesModal.details.CIGRE')} (${createCertificateLink(`${process.env.PUBLIC_URL}/template.pdf`, t, t('ConferencesModal.customCertText'))})`,
                    className: 'emph',
                  },
                { text: t('ConferencesModal.details.energynet2021'), className: 'emph' },
                { text: t('ConferencesModal.details.energynet2020'), className: 'emph' },
                { text: t('ConferencesModal.details.powerBIMeetup'), className: 'emph' },
                { text: `${t('ConferencesModal.details.Umnik')} (${createCertificateLink(`${process.env.PUBLIC_URL}/umnik.png`, t)})`, className: 'emph' },
                { text: t('ConferencesModal.details.ibima'), className: 'emph' },
                { text: t('ConferencesModal.details.industrialManagement'), className: 'emph' },
            ],
        },
    ];
}